<template>
  <GreenStatusPage :title="title">
    <template v-slot:primary-reason-content>
      <span class="discretion-title">Your renewal application requires further review</span>
    </template>
    <template v-slot:description-content>
      <div class="discretion-body">
        <span>
          Our underwriters need to take a closer look at your application and</span>
        <br>
        <span>business before we can provide a decision. We’ll follow up with additional</span>
        <br>
        <span>details via <span class="discretion-body-bold">email within three to four business days.</span> If you have any
          questions, our</span>
        <br>
        <span>Insurance Advisors are standing by to chat.</span>
      </div>
    </template>
    <template v-slot:status-button>
      <div class="discretion-buttons">
        <ButtonPrimary
          class="discretion-button"
          @click="showChat"
        >
          Talk to an advisor
        </ButtonPrimary>
        <ButtonPrimary
          class="discretion-button"
          @click="goToAccount"
        >
          Go to your account
        </ButtonPrimary>
      </div>
    </template>
  </GreenStatusPage>
</template>

<script>
import GreenStatusPage from '@/shared/views/layouts/GreenStatusPage';
import ButtonPrimary from '@/shared/components/ui/atoms/buttons/ButtonPrimary';
import { rerouteAuthenticatedUser } from '@/onboarding/router/rerouteUser';
import { showChatIfEnabled } from '@/onboarding/services/chatHelper';
import { sendToAccountPath } from '@/onboarding/router/routeHelper';

export default {
  name: 'QuoteBlockingRenewal',
  dependencies: ['tracking'],
  components: { ButtonPrimary, GreenStatusPage },
  props: {
    applicationId: {
      type: String,
      required: true,
    },
  },
  async created() {
    if (!this.applicationId) {
      this.$router.replace('/loginportal/send');
    }
    const { next } = await rerouteAuthenticatedUser({
      router: this.$router,
      givenApplicationId: this.applicationId,
    });
    if (next) {
      this.$router.replace(next);
    }
  },
  methods: {
    showChat() {
      showChatIfEnabled({ tracking: this.tracking });
    },
    goToAccount() {
      sendToAccountPath('/members/policies');
    },
  },
};
</script>

<style lang="scss" scoped>
.discretion-title {
  font-size: $font-size-l;
  font-weight: 500;
}

.discretion-body {
  @include description-text;
}

.discretion-body-bold {
  font-weight: 700;
}

.discretion-buttons {
  display: flex;
  justify-content: space-around;
  align-content: space-between;
  flex-flow: row wrap;
}

.discretion-button {
  margin: 10px;
}
</style>
