<template>
  <GenericStatusPage :title="title">
    <template v-slot:primary-reason-content>
      <span v-html="primaryReason" />
    </template>
    <template v-slot:description-content>
      <span v-html="description" />
    </template>
    <template v-slot:status-button>
      <div class="discretion-buttons">
        <ButtonPrimary
          v-if="isNonRenewalNicheBlock"
          class="discretion-button"
          @click="routeToReferral"
        >
          Get coverage through Hiscox
        </ButtonPrimary>
        <ButtonPrimary
          v-else
          class="discretion-button"
          @click="showChat"
        >
          Talk to an advisor
        </ButtonPrimary>
        <ButtonPrimary
          v-if="isRenewalApplication"
          class="discretion-button"
          @click="goToAccount"
        >
          Go to your account
        </ButtonPrimary>
      </div>
    </template>
  </GenericStatusPage>
</template>

<script>
import GenericStatusPage from '@/shared/views/layouts/GenericStatusPage';
import ButtonPrimary from '@/shared/components/ui/atoms/buttons/ButtonPrimary';
import selectors from '@/onboarding/lib/selectors/decisionSelectors';
import { applicationIsRenewal } from '@/onboarding/lib/selectors/storeSelectors';
import { showChatIfEnabled } from '@/onboarding/services/chatHelper';
import { sendToAccountPath } from '@/onboarding/router/routeHelper';

const noPrequalReasons = {
  unsupported_location: 'We aren’t in your state yet.',
  unsupported_niche: 'We’ll need talk with you to cover your particular business activities.',
  too_many_people:
    'Vouch focuses on insuring early startups. You’ve already scaled to the next level.',
  too_much_capital:
    'Vouch focuses on insuring early startups. You’ve already scaled to the next level.',
  because_high_historic_claim:
    'Thanks for your input. After reviewing your application, we unfortunately cannot provide coverage for you at this time given your prior claim or litigation activity.',
};

const genericError = 'Unfortunately, Vouch is not a good fit for your company right now.';

const genericDescription = `Vouch's proprietary coverages are not currently designed to cover companies like yours. If you have any questions, please reach out to an Insurance Advisor.`;

export default {
  name: 'FinPage',
  dependencies: ['requests', 'tracking'],
  components: { ButtonPrimary, GenericStatusPage },
  props: {
    applicationId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      decisionData: {
        decision: null,
        reason_token: null,
      },
      title: genericError,
      primaryReason: '',
      description: genericDescription,
    };
  },
  computed: {
    isRenewalApplication() {
      return applicationIsRenewal(this);
    },
    isNonRenewalNicheBlock() {
      return this.decisionData.reason_token === 'blocked_niche_non_renewing';
    },
  },
  async created() {
    const onSuccess = data => {
      this.decisionData = data;
      if (selectors.isMaybeDecision(this.decisionData)) {
        this.title = 'APPLICATION REVIEW';
        this.primaryReason =
          'Hang tight; our underwriters need to take a closer look at your answers before we can provide a decision.';
        this.description =
          'Sorry for the slight delay. Your dedicated Vouch Account Manager will get back to you via email within 3-4 business days.  You can close this tab.';
      }
      if (selectors.isNoDecision(this.decisionData)) {
        this.title = 'Hold on...';
        this.primaryReason = `${noPrequalReasons[this.decisionData.reason_token] || genericError}`;
        this.description =
          'Seems like your startup has changed a lot.  Our licensed startup insurance advisors will need to chat with you to make sure you are fully covered.  They will follow up in 48 hours or you can chat with them by clicking on the button below.';
      }
      if (this.decisionData.reason_token === 'locations_are_incompatible') {
        this.title = 'Hold on...';
        this.primaryReason = `To obtain coverage for the office location(s) you have provided, you need to speak to an Insurance Advisor.`;
        this.description = `You can also email us at
            <a href="mailto:success@vouch.us">success@vouch.us</a>`;
      }
      if (this.decisionData.reason_token === 'blocked_niche_non_renewing') {
        this.title = '';
        this.primaryReason = ``;
        this.description = `Our analysis of your business has concluded that the right insurance carrier for you to work with is Hiscox. We've partnered with their Small Business Unit to help you quickly find insurance coverage that fits your business.`;
        this.buttonText = 'GET COVERAGE THROUGH HISCOX';
      }
    };

    this.requests.getApplicationDecision({ applicationId: this.applicationId, onSuccess });
  },
  methods: {
    onClick() {
      sendToAccountPath('/members/');
    },
    showChat() {
      showChatIfEnabled({ tracking: this.tracking });
    },
    routeToReferral() {
      if (this.decisionData.reason_token === 'blocked_niche_non_renewing') {
        window.location.replace(
          'https://www.hiscox.com/small-business-insurance/vouch?utm_source=vouch&utm_medium=referral&utm_campaign=vouch_august_newsletter&leadorigin=vouch'
        );
      }
    },
    goToAccount() {
      sendToAccountPath('/members/policies');
    },
  },
};
</script>

<style lang="scss" scoped>
.discretion-buttons {
  display: flex;
  justify-content: space-around;
  align-content: space-between;
  flex-flow: row wrap;
}

.discretion-button {
  margin: 10px;
}
</style>
